export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [];

export const dictionary = {
		"/documents": [3,[2]],
		"/documents/settings": [8,[2]],
		"/documents/[documentId]": [4,[2]],
		"/documents/[documentId]/rapports/[rapportId]": [5,[2]],
		"/documents/[documentId]/rapports/[rapportId]/position/[positionId]": [6,[2]],
		"/documents/[documentId]/rapports/[rapportId]/position/[positionId]/extent/[extentId]": [7,[2]],
		"/login": [9],
		"/mobile-settings": [10]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';